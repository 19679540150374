import axios from 'axios';

const API_URL = 'https://api-vicius.kaizensoftware.com.ec/api';

const getToken = () => {
    return localStorage.getItem('token');
};

export const getAllProducts = async (page, searchTerm, branchId, categoryId) => {
    try {
        const response = await axios.get(`${API_URL}/product/get-all-products`, {
            params: { 
                pageNumber: page,
                search: searchTerm,
                branchId: branchId,
                categoryId: categoryId || undefined // Agregar categoryId si está definido
            },
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};



export const addNewProduct = async (branchId,formData) => {
    console.log('Datos de la nueva categoría:', formData);
    try {
        const response = await axios.post(`${API_URL}/product/add-new-product/${branchId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            }
        });
        const notificationData = {
            heading: 'Descubre Nuevos Productos para Ti',
            content: `${formData.get('name')} Esperan por ti`
        };
        await sendNotificationToAll(notificationData);

        return response.data;
    } catch (error) {
        console.error('Error creating product:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const deleteExistingProduct = async (productId) => {
    try {
        await axios.delete(`${API_URL}/Product/delete-existing-product/${productId}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
    } catch (error) {
        console.error('Error deleting product:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getAllCategories = async (branchId) => {
    try {
        const response = await axios.get(`${API_URL}/category/get-all-categories`, {
            params: {
                branchId: branchId
            },
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};

export const addNewCategory = async (branchId,newCategory) => {
    console.log('Datos de la nueva categoría:', newCategory);
    try {
        const response = await axios.post(`${API_URL}/category/add-new-category/${branchId}
            `, newCategory, {
                
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating category:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const productUpdate = async (id, productData) => {
    try {
        const response = await axios.put(`${API_URL}/product/update-existing-product/${id}`, productData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating product:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getAllPrints = async () => {
    try {
        const response = await axios.get(`${API_URL}/Print/get-all-prints`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching prints:', error);
        throw error;
    }
};

export const getAllUsers = async () => {
    try {
        const response = await axios.get(`${API_URL}/user/get-all-users`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/account/login`, { email, password });
        if (response.status === 200) {
            const token = response.data.token;
            localStorage.setItem('token', token);
            return token;
        } else {
            throw new Error('Credenciales incorrectas');
        }
    } catch (error) {
        console.error('Error al iniciar sesión:', error);
        throw error;
    }
};


export const getAllOrders = async () => {
    try {
        const response = await axios.get(`${API_URL}/order/get-all-orders`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const resetPassword = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/account/reset-password`, { email });
        return response.data;
    } catch (error) {
        console.error('Error sending reset password email:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const changePassword = async (token, newPassword) => {
    try {
        const response = await axios.post(`${API_URL}/account/change-password/${token}`, { newPassword });
        return response.data;
    } catch (error) {
        console.error('Error changing password:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const sendNotificationToAll = async (notificationData) => {
    try {
        const response = await axios.post(`${API_URL}/notification/send-notification-to-all`, notificationData, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error sending notification:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const verifyEmail = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/account/verify-email/${token}`);
        return response.data;
    } catch (error) {
        console.error('Error verifying email:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getProductById = async (productId) => {
    try {
        const response = await axios.get(`${API_URL}/product/get-product-by-id/${productId}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching product by ID:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getAllBaranches = async () => {
    try {
        const response = await axios.get(`${API_URL}/branch/get-all-branches`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw error;
    }
};


export const getAllBranches = async () => {
    try {
        const response = await axios.get(`${API_URL}/branch/get-all-branches`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching branches:', error);
        throw error;
    }
};



export const addNewBranch = async (newBranch) => {
    try {
        const response = await axios.post(`${API_URL}/branch/add-new-branch`, newBranch, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error adding new branch:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const deleteBranchById = async (branchId) => {
    try {
        await axios.delete(`${API_URL}/branch/delete-branch-by-id/${branchId}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        console.log(`Sucursal con ID ${branchId} eliminada correctamente.`);
    } catch (error) {
        console.error('Error deleting branch:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const updateBranchById = async (branchId, updatedBranch) => {
    try {
        const response = await axios.put(`${API_URL}/api/branch/update-branch-by-id/${branchId}`, updatedBranch);
        return response.data; 
    } catch (error) {
        throw error; 
    }
};
export const deleteCategoryById = async (categoryId) => {
    try {
        const response = await axios.delete(`${API_URL}/category/delete-category-by-id/${categoryId}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        console.log(`Categoría con ID ${categoryId} eliminada correctamente.`);
        return response.data;
    } catch (error) {
        console.error('Error deleting category:', error.response ? error.response.data : error.message);
        throw error;
    }
};
export const addNewAdvertisement = async (branchId,newAdvertisement) => {
    //console.log('Datos de la nueva categoría:', newAdvertisement);
    try {
        const response = await axios.post(`${API_URL}/advertisement/add-new-advertisement/${branchId}
            `, newAdvertisement, {
                
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating Advertisement:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getAllAdvertisement = async (branchId) => {
    try {
        const response = await axios.get(`${API_URL}/advertisement/get-advertisements-by-branch-id/${branchId}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Advertisement:', error);
        throw error;
    }
};


export const deleteExistingAdvertisement = async (advertisementId) => {
    try {
        await axios.delete(`${API_URL}/advertisement/delete-advertisement-by-id/${advertisementId}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
    } catch (error) {
        console.error('Error deleting anuncio:', error.response ? error.response.data : error.message);
        throw error;
    }
};


export const advertisementUpdate = async (id, advertisementData) => {
    try {
        const response = await axios.put(`${API_URL}/advertisement/update-advertisement-by-id/${id}`, advertisementData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating product:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const updateOrderStatus = async (orderId, status) => {
    try {
        const response = await axios.put(
            `${API_URL}/order/update-order-status/${orderId}`, 
            { orderStatus: status }, // Asegúrate de enviar el estado correctamente
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json',
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating order status:', error.response ? error.response.data : error.message);
        throw error;
    }
};
