import React, { useState } from 'react';
import imagen from '../images/vicius final.png';
import { FiPrinter } from "react-icons/fi";
import { IoMdHome } from "react-icons/io";
import { FaBasketShopping } from "react-icons/fa6";
import { MdAddShoppingCart } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import "../styles/Sidebar.css";
import { RiAdvertisementFill } from "react-icons/ri";
import { ImOffice } from "react-icons/im";
const Sidebar = ({ onMenuClick, onLogout }) => {
    const [activeItem, setActiveItem] = useState('dashboard');

    const handleMenuClick = (item, label) => {
        setActiveItem(item);
        onMenuClick(item, label);
    };

    // const handleLogout = () => {
    //     showExitSession(() => {
    //         onLogout(); 
    //     });
    // };

    return (
        <div className="menu">
            <div className="logo">
                <img src={imagen} alt="logo-icon" />
               Panel Vicius   
            </div>
            <hr className="divider" />
            <div className="menu--list">
            <a
                    href="#"
                    className={`item ${activeItem === 'dashboard' ? 'active' : ''}`}
                    onClick={() => handleMenuClick('dashboard', 'Dashboard')}
                >
                    <IoMdHome className="logo-icon" />
                    Dashboard
                    {activeItem === 'dashboard' && <div className="triangle"></div>}
                    
                </a>
            <a
                    href="#"
                    className={`item ${activeItem === 'branch' ? 'active' : ''}`}
                    onClick={() => handleMenuClick('branch', 'Sucursales')}
                >
                    <ImOffice className="logo-icon" />
                    Sucursales
                    {activeItem === 'branch' && <div className="triangle"></div>}
                </a>
                <a
                    href="#"
                    className={`item ${activeItem === 'productos' ? 'active' : ''}`}
                    onClick={() => handleMenuClick('productos', 'Productos')}
                >
                    <FaBasketShopping className="logo-icon" />
                    Productos
                    {activeItem === 'productos' && <div className="triangle"></div>}
                </a>
               
               
                <a
                    href="#"
                    className={`item ${activeItem === 'orders' ? 'active' : ''}`}
                    onClick={() => handleMenuClick('orders', 'Pedidos')}
                >
                    <MdAddShoppingCart className="logo-icon" />
                    Pedidos
                    {activeItem === 'orders' && <div className="triangle"></div>}
                </a>
                <a
                    href="#"
                    className={`item ${activeItem === 'users' ? 'active' : ''}`}
                    onClick={() => handleMenuClick('users', 'Usuarios')}
                >
                    <FaUserEdit className="logo-icon" />
                    Usuarios
                    {activeItem === 'users' && <div className="triangle"></div>}
                </a>
                <a
                    href="#"
                    className={`item ${activeItem === 'advertisement' ? 'active' : ''}`}
                    onClick={() => handleMenuClick('advertisement', 'Anuncios')}
                >
                    <RiAdvertisementFill className="logo-icon" />
                    Anuncios
                    {activeItem === 'advertisement' && <div className="triangle"></div>}
                </a>
                {/* <a
                    href="#"
                    className={`item ${activeItem === 'logout' ? 'active' : ''}`}
                    onClick={handleLogout}
                >
                    <BiLogOut className="logo-icon-out" />
                    Cerrar sesión
                </a> */}
            </div>
        </div>
    );
};

export default Sidebar;